<template>
    <div class="pa-4 mb-5">
        <v-row class="mb-4 mt-3">

            <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="abre_personal()">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/empleados.png"></v-img>
                        <h4 block class="text-center pa-1">Personal</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="dial_periodos = true">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/repor.png"></v-img>
                        <h4 block class="text-center pa-1">Reportes</h4>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dial_periodos" max-width="450px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_periodos = !dial_periodos">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-select :items="$store.state.array_periodos" label="PERIODO" outlined v-model="periodo"
                    dense></v-select>
                <v-row class="mb-4 mt-3">

                    <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                        <v-card @click.prevent="abre_marca()">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/huella.png"></v-img>
                                <h4 block class="text-center pa-1">Marcaciones</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                        <v-card @click.prevent="abre_total()">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/repor.png"></v-img>
                                <h4 block class="text-center pa-1">Reporte Total</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>

        <lista_personal v-if="dial_personal" @cierra="dial_personal = false" />
        <marc v-if="marcas" @cerrar="marcas = false" :periodo="periodo" :data="tot_mar">
        </marc>
        <rep_total v-if="rep_tot" @cerrar="rep_tot = false" :periodo="periodo" :data="tot_mar">
        </rep_total>
    </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import moment from 'moment'
import lista_personal from "./lista_personal.vue";
import marc from "./rep_marca.vue";
import rep_total from "./rep_total_marc.vue";
import {
    all_grupo,
    lee_marca_asis
} from '../../db'
export default {
    name: 'panel',
    components: {
        lista_personal,
        marc,
        rep_total
    },
    data() {
        return {
            rep_tot: false,
            periodo: moment(String(new Date)).format('MM-YYYY'),
            dial_personal: false,
            dial_periodos: false,
            marcas: false,
            tot_mar: []
        }
    },
    created() {
        this.iniciar()
    },
    beforeDestroy() {
        all_grupo().off('value')
    },

    methods: {
        iniciar() {
            all_grupo().on('value', (snapshot) => {
                let array = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        let key = item.key;
                        data.id = key
                        array.push(data);
                    });
                    store.commit('grupos', array)
                }
            })
        },
        abre_personal() {
            this.dial_personal = true
        },
        async abre_marca() {
            this.tot_mar = []
            this.tot_mar = await this.consulta_marcas()
            var dias_mes = moment(String(this.periodo), 'MM-YYYY').daysInMonth() + 1
            console.log(dias_mes)
            var lista_empleados = ''
            var array_empleados = store.state.empleados
            var empleados = new Array(array_empleados.length)
            for (var i = 0; i < array_empleados.length; i++) {
                var data = array_empleados[i]
                empleados[i] = new Array(dias_mes);

                for (var e = 1; e < dias_mes; e++) {

                    empleados[i][0] = {
                        nombre: data.nombre,
                        id: data.id,
                        grupo: data.grupo
                    }
                    var fec = ((e).toString().padStart(2, 0) + '-' + this.periodo)

                    var marca = this.tot_mar.find(m =>
                        m.documento === data.id &&
                        moment.unix(m.fecha).format('DD-MM-YYYY') === fec
                    );
                    if (marca) {
                        empleados[i][e] = {
                            fecha: fec,
                            estado: 'A', // Presente
                            h_ingreso: marca.modo === 'ingreso' ? moment.unix(marca.fecha).format('HH:mm') : '',
                            h_salida: marca.modo === 'salida' ? moment.unix(marca.fecha).format('HH:mm') : '',
                            observacion: ''
                        };
                    } else {
                        // Si no hay marca, marcar como falta (F)
                        empleados[i][e] = {
                            fecha: fec,
                            estado: 'F', // Falta
                            h_ingreso: '',
                            h_salida: '',
                            observacion: ''
                        };
                    }
                }
            }
            this.tot_mar = empleados
            this.marcas = true
        },
        async abre_total() {
            this.tot_mar = []
            this.tot_mar = await this.consulta_marcas()
            this.rep_tot = true
        },
        async consulta_marcas() {
            store.commit("dialogoprogress", 1)
            var inicio = moment('01-' + this.periodo, 'DD-MM-YYYY').startOf('month').format('YYYY-MM-DD');
            var finDelMes = moment('01-' + this.periodo, 'DD-MM-YYYY').endOf('month');
            var hoy = moment();
            var fin = hoy.isBefore(finDelMes) ? hoy.format('YYYY-MM-DD') : finDelMes.format('YYYY-MM-DD');
            var snap = await lee_marca_asis().orderByChild('fecha')
                .startAt(moment(String(inicio)) / 1000)
                .endAt(moment(String(fin)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).once('value')

            var array = []
            if (!snap.exists()) {
                alert("sin datos")
                return
            }
            snap.forEach((item) => {
                let data = item.val();
                let key = item.key;
                data.id = key
                array.push(data);
            });
            store.commit("dialogoprogress", 1)
            return array
        }
    },

}
</script>
