<template>
    <v-dialog v-model="dial" fullscreen hide-overlay transition="dialog-bottom-transition">
        <div>
            <v-system-bar window dark height="40">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>

                <v-spacer></v-spacer>
                <h3>Periodo : {{ periodo }}</h3>
            </v-system-bar>
        </div>

        <v-card class="">
            <!-- Filtros -->
            <v-container class="pa-4">
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="filters.nombre" label="Filtrar por nombre" solo clearable
                            prepend-icon="mdi-account-search"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-select v-model="filters.modo" :items="['ingreso', 'salida']" label="Filtrar por modo" solo
                            clearable prepend-icon="mdi-swap-vertical"></v-select>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="filters.fecha" label="Filtrar por fecha" readonly solo
                                    prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.fecha" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row justify="end">
                    <v-btn @click="resetFilters" color="primary" class="mr-3" outlined>
                        Limpiar filtros
                    </v-btn>
                </v-row>
            </v-container>
            <table>
                <thead>
                    <tr>
                        <th>Documento</th>
                        <th>Nombre</th>
                        <th>Fecha</th>
                        <th>Modo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(entry, index) in filteredReportData" :key="index">
                        <td>{{ entry.documento }}</td>
                        <td>{{ entry.nombre }}</td>
                        <td>{{ formatDate(entry.fecha) }}</td>
                        <td>{{ entry.modo }}</td>
                    </tr>
                </tbody>
            </table>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'
import store from '@/store/index'
import {
    nuevo_medicion,
} from '../../db'
export default {

    props: {
        data: '',
        periodo: null
    },
    name: 'caja',
    data() {
        return {
            dial: false,
            menu: false, // Control del date-picker
            filters: {
                nombre: '', // Filtro por nombre
                modo: '',   // Filtro por modo (ingreso/salida)
                fecha: ''   // Filtro por fecha
            }

        }
    },
    created() {
        this.dial = true

    },
    computed: {
        filteredReportData() {
            return this.reportData.filter(entry => {
                // Filtrar por nombre
                const matchesNombre = entry.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase());

                // Filtrar por modo (ingreso/salida)
                const matchesModo = !this.filters.modo || entry.modo === this.filters.modo;

                // Filtrar por fecha
                const matchesFecha = !this.filters.fecha || moment(entry.fecha * 1000).isSame(this.filters.fecha, 'day');

                return matchesNombre && matchesModo && matchesFecha;
            });
        },
        reportData() {
            return Object.values(this.data);
        }
    },
    methods: {
        formatDate(timestamp) {
            const date = new Date(timestamp * 1000); // Convertir de segundos a milisegundos
            return date.toLocaleString(); // Formato legible
        },
        // Limpiar todos los filtros
        resetFilters() {
            this.filters = {
                nombre: '',
                modo: '',
                fecha: ''
            };
        },
        cierra() {
            this.$emit('cerrar', true);
        }
    },

}
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.filters .v-btn {
    margin-top: 10px;
}

.v-select,
.v-text-field,
.v-btn {
    margin-bottom: 20px;
}
</style>