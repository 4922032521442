<template>
    <div class="mb-6 pa-4 mt-3">
        <v-btn v-if="false" @click="ejectua()">as</v-btn>
        <h4>Almacen General Monturas</h4>
        <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n2 mb-n3' : 'mb-n3 mt-n2'">
            <v-col cols="8">
                <v-row dense>
                    <v-col cols="3">
                        <v-card elevation="12" class="mt-3 mb-4" color="white" @click="nuevo()">
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/producto.png"></v-img>
                                <p class="text-center mb-n2 mt-2"> Nuevo</p>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card elevation="12" class="mt-3 mb-4" color="white" @click="envio_tienda()">
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/envio.png"></v-img>
                                <p class="text-center mb-n2 mt-2"> Envio Tienda</p>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="3" v-if="false">
                        <v-card elevation="12" class="mt-3 mb-4" color="white" @click="dial_impresion = true">
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/disc.png"></v-img>
                                <p class="text-center mb-n2 mt-2"> Imprime </p>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card elevation="12" class="mt-3 mb-4" color="white" @click="dial_busqueda = true">
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/filtro.png"></v-img>
                                <p class="text-center mb-n2 mt-2"> Busca Montura</p>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

            </v-col>

            <v-col cols="2">
                <v-select :items="array_ubica" label="Ubicacion" dense outlined v-model="ubicacion"></v-select>
            </v-col>
            <v-col cols="2">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>
        <v-card class="pa-3">

            <v-data-table :headers="headers" :search="buscar" :items="listafiltrada" dense :items-per-page="17"
                mobile-breakpoint="1">
                <template v-slot:item.actions="{ item }">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-icon color="green" @click="editar(item)">mdi-lead-pencil</v-icon>
                            <v-icon color="info" class="ml-6" @click="imprime(item)">mdi-printer-wireless</v-icon>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="750px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="red" large @click="dialogo_elimina = !dialogo_elimina">mdi-delete</v-icon>
                    <v-spacer></v-spacer>
                    <v-switch v-model="imprime_auto" color="green" label="Auto"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="agregar()">mdi-content-save</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="info" large class="ml-6" @click="imprime(mont_selecta)">mdi-printer-wireless</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-select :disabled="edita_ubica()" :items="array_ubica" label="Ubicacion" dense outlined
                            v-model="ubicacion"></v-select>

                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field readonly @click:append="activa_proveedor = true" append-icon="mdi-magnify"
                            outlined dense type="text" v-model="proveedor" label="Proveedor"> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense disabled v-model="id" label="ID" outlined></v-text-field>
                    </v-col>

                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea id="model" dense outlined v-model="marca" auto-grow filled label="Marca"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field dense v-model="modelo" label="Modelo" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select dense v-model="tipo" :items="array_tipo" menu-props="auto" hide-details outlined
                            label="Tipo"></v-select>
                    </v-col>

                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field dense v-model="color" label="Color" outlined>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select dense v-model="material" :items="$store.state.tipo_material_montura" menu-props="auto"
                            hide-details outlined label="Tipo"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="costo" label="costo"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="precio" label="Precio venta"></v-text-field>
                    </v-col>

                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="precio_min"
                            label="Precio minimo"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field @keyup.enter="agregar()" outlined dense type="number" v-model="stock"
                            label="Stock"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogo_elimina" max-width="300">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_elimina = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">Esta seguro de Eliminar?</h4>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block @click="eliminar()" color="orange">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_busqueda" max-width="300">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_busqueda = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-text-field class="id_mon" autofocus v-model="id_montura" label="Codigo Montura" outlined
                    @keyup.enter="busqueda()" append-icon="mdi-barcode"></v-text-field>
                <v-card elevation="12" class="mt-3 mb-4" color="white" @click="busqueda()">
                    <v-container>
                        <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/filtro.png"></v-img>
                        <p class="text-center mb-n2 mt-2"> Busca Montura</p>
                    </v-container>
                </v-card>
            </v-card>
        </v-dialog>
        <form id="id_array" method="post" action="http://localhost:8082/printertk" target="TheWindow">
            <input id="datas" type="hidden" name="result" value=''>
        </form>

        <t_proveedor v-if="activa_proveedor" :edicion="false" @cierra="activa_proveedor = false"
            @array="selec_proveedor($event)" />
        <t_colores :dial="activa_colores" :edicion="false" @cierra="activa_colores = false"
            @array="selec_color($event)" />
    </div>
</template>

<script>
import t_proveedor from '@/components/configEmpresa/tabla_proveedor'
import t_colores from '@/components/configEmpresa/tabla_colores'
import axios from "axios"
import {
    allMonturas,
    nuevaMontura,
    eliminaMontura,
    busca_Monturas,
    busca_Monturas_v,
    nuevaMontura_v,
    allMonturas_v
} from '../../db'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
import store from '@/store/index'
import {
    pc_print
} from '../../imprime_etiquetas'
export default {
    name: 'caja',
    components: {
        t_proveedor,
        t_colores
    },
    data() {
        return {
            imprime_auto: true,
            array_imprime: [],
            dial_busqueda: false,
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Marca',
                value: 'marca',
            },
            {
                text: 'Modelo',
                value: 'modelo',
            },
            {
                text: 'Ubicacion',
                value: 'ubicacion',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            ],
            activa_proveedor: false,
            activa_colores: false,
            dialogo_agrega: false,
            dialogo_elimina: false,
            array_tabla: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            tipoproducto: "BIEN",
            operacion: "GRAVADA",
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA',
                'INAFECTA'
            ],
            array_tipo: [
                'AL AIRE',
                'SEMI AL AIRE',
                'ARO COMPLETO'
            ],
            edit: false,
            edita: false,
            tipo: 'ARO COMPLETO',
            id: '',
            marca: '',
            nombre: '',
            proveedor: '',
            modelo: '',
            color: '',
            costo: '',
            precio_min: '',
            precio: '',
            stock: 1,
            material: 'ACETATO',
            id_color: '',
            buscar: '',
            ubicacion: 'almacen',
            array_ubica: [],
            id_montura: '',
            mont_selecta: []
        }
    },
    mounted() {
        //allMonturas().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allMonturas().off();
    },
    computed: {
        listafiltrada() {
            if (this.ubicacion == 'todos') {
                allMonturas().limitToFirst(50).on("value", this.onDataChange);
            } else {
                allMonturas().limitToFirst(50).orderByChild('ubicacion').equalTo(this.ubicacion).on("value", this.onDataChange);
            }

            return this.array_tabla.filter(item => item.stock != 0)
        }
    },
    created() {
        this.array_ubica = store.state.tiendas
        this.array_ubica.unshift('almacen')

        this.array_ubica.unshift('todos')
    },

    methods: {
        edita_ubica() {
            console.log(this.edit)
            this.edita = store.state.permisos.master
            if (this.edita) {
                if (store.state.permisos.master) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }

        },
        async busqueda() {
            if (this.id_montura[0] == 'M') {
                var cod = this.id_montura
            } else {
                var cod = 'M' + this.id_montura.toString().padStart(6, 0)
            }
            console.log(cod)
            var snap = await busca_Monturas(cod).once('value')
            if (snap.exists()) {
                this.editar(snap.val())
                this.id_montura = ''
                //this.dial_busqueda = false
            } else {
                var snaps = await busca_Monturas_v(cod).once('value')
                if (snaps.exists()) {
                    this.editar(snaps.val())
                    this.id_montura = ''
                } else {
                    alert('MONTURA NO EXISTE!!')
                    return
                }
            }
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();

                array.push(data);
            });
            this.array_tabla = array;
        },
        async ejectua() {
            var snap = await allMonturas().limitToFirst(50).once('value')

            snap.forEach((item) => {
                let data = item.val();
                if (data.stock == 0) {
                    // this.limpia_monturas(data)
                }
            });
        },
        async limpia_monturas(data) {
            if (data.id != undefined) {
                await nuevaMontura_v(data.id, data)
                eliminaMontura(data.id)
            }

        },
        selec_proveedor(data) {
            this.proveedor = data.rsocial
            this.activa_proveedor = false
        },
        selec_color(data) {
            this.color = data.nombre
            this.id_color = data.codigo
            this.activa_colores = false
        },
        async nuevo() {
            this.edit = false
            var a = await obten_contador_externo("orden_monturas")
            this.id = "M" + a
            this.tipo = 'ARO COMPLETO'
            this.marca = ''
            this.modelo = ''
            this.proveedor = ''
            this.color = ''
            this.id_color = ''
            this.costo = ''
            this.precio_min = ''
            this.stock = 1
            this.precio = ''
            this.ubicacion = 'almacen'
            this.dialogo_agrega = true
        },
        async agregar() {
            if (this.precio == '' || this.costo == '' || this.modelo == '' || this.material == '' || this.precio_min == '' || this.marca == '') {
                alert('COMPLETE DATOS')
                return
            }
            store.commit("dialogoprogress", 1)
            var array = {
                tipoproducto: this.tipoproducto,
                operacion: this.operacion,
                id: this.id,
                tipo: this.tipo,
                marca: this.marca,
                modelo: this.modelo.toString().toUpperCase().trim(),
                proveedor: this.proveedor,
                color: this.color,
                id_color: this.id_color,
                material: this.material,
                costo: this.costo,
                precio_min: this.precio_min,
                stock: this.stock,
                precio: this.precio,
                ubicacion: this.ubicacion
            }

            if (!this.edit) {
                for (var i = 0; i < this.stock; i++) {
                    var a = await obten_contador_externo("orden_monturas")
                    array.id = "M" + a
                    this.id = "M" + a
                    console.log(this.id)
                    array.stock = 1
                    await nuevaMontura(array.id, array)
                    await sumarCorrelativo_externo("orden_monturas", a)
                    if (this.imprime_auto) {
                        await new Promise(resolve => setTimeout(resolve, 1000))
                        this.imprime(array)
                    }
                }
            } else {
                await nuevaMontura(this.id, array)
                this.dialogo_agrega = false
            }

            this.costo = ''
            this.modelo = ''
            this.stock = 1
            this.precio = ''
            this.precio_min = ''
            this.material = 'ACETATO'
            await new Promise(resolve => setTimeout(resolve, 1000))
            store.commit('dialogosnackbar', 'GUARDADO CON EXITO!')
            store.commit("dialogoprogress", 1)
            await new Promise(resolve => setTimeout(resolve, 100))
            document.getElementById('id_mon').focus();
            document.getElementById('model').focus();
        },
        editar(data) {
            this.mont_selecta = data
            this.edit = true
            this.tipoproducto = data.tipoproducto
            this.operacion = data.operacion
            this.id = data.id
            this.tipo = data.tipo
            this.marca = data.marca
            this.modelo = data.modelo
            this.proveedor = data.proveedor
            this.color = data.color
            this.id_color = data.id_color
            this.material = data.material
            this.costo = data.costo
            this.precio_min = data.precio_min
            this.stock = data.stock
            this.precio = data.precio
            this.ubicacion = data.ubicacion
            this.dialogo_agrega = true
        },
        eliminar() {
            eliminaMontura(this.id)
            this.dialogo_elimina = false
            this.dialogo_agrega = false
        },
        cierra() {
            this.$emit('cierra', false)
        },
        envio_tienda() {
            this.$router.push({
                name: 'envio_monturas'
            })
        },
        imprime(data) {
            pc_print(data)
            var nom_impresora = 'ZDesigner GT800 (EPL)' //
            //var nom_impresora = 'Microsoft Print to PDF'
            var ticket = {
                'impresion': [{
                    'impresora': nom_impresora,
                    'formato': "qr.lrf",
                    'codigo': data.id,
                    'qr': data.id,
                    'precio': 'S/.' + parseFloat(data.precio).toFixed(2),
                    'modelo': data.modelo,
                    'marca': data.marca,
                }]
            }
            console.log(ticket)
            //this.array_imprime = JSON.stringify(ticket)
            document.getElementById("datas").value = JSON.stringify(ticket)
            var a = window.open('', 'TheWindow', 'left=5000,top=5000,width=0,height=0')
            document.getElementById('id_array').submit()
            setTimeout(function () {
                a.close();
                //  resolve(true)
            }, 1000);
        }
    },

}
</script>
