<template>
    <div :style="itemStyle" :class="itemClass">{{ itemSymbol }}</div>
</template>

<script>
export default {
    props: {
        top: { type: Number, required: true },
        left: { type: Number, required: true },
        rotation: { type: Number, required: true },
        type: { type: String, required: true } // Propiedad para definir el tipo: 'bat' o 'pumpkin'
    },
    computed: {
        itemStyle() {
            return {
                position: 'absolute',
                top: `${this.top}px`,
                left: `${this.left}px`,
                transform: `rotate(${this.rotation}deg)`,
                fontSize: '3em'
            };
        },
        itemClass() {
            return this.type === 'bat' ? 'bat' : 'pumpkin';
        },
        itemSymbol() {
            return this.type === 'bat' ? '🦇' : '🎃';
        }
    }
};
</script>

<style scoped>
.bat,
.pumpkin {
    transition: transform 0.1s linear, top 0.1s linear, left 0.1s linear;
}
</style>