<template>
    <v-dialog v-model="dial" max-width="750" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>AGREGA RECETA EXTERNA</h4>
                <v-spacer></v-spacer>
                <v-icon color="green" large @click="Guarda_medicion()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3" style="overflow-y: scroll;overflow-x: hidden; max-height:70vh">
            <v-card class="pa-1">
                <v-row dense class="mb-n7">
                    <v-col cols="6">
                        <p style="font-size:85%;"> <strong>Paciente :</strong> {{ nom_paciente }}
                        </p>
                        <p style="font-size:85%;color:red" class="pa-4 mt-n8 mb-n2"> {{ obten_serie() }}
                        </p>
                    </v-col>
                    <v-col cols="3">

                    </v-col>
                    <v-col cols="3">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date"
                            label="Fecha medicion"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

            <v-row class="mt-11">
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold mt-n13 mb-n4">
                            <v-icon class="ml-1 mr-1" color="red" dark>mdi-eye-outline</v-icon>OD
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item class="mb-n4">
                                <v-text-field :prepend-inner-icon="sim_esfe_od" @click:prepend-inner="simbo_esfe_od()"
                                    outlined dense v-model="esfera_od" type="number" label="ESFERA"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n4">
                                <v-text-field prepend-inner-icon="mdi-minus" outlined dense v-model="cilindro_od"
                                    type="number" label="CILINDRO"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n4">
                                <v-text-field outlined dense v-model="eje_od" type="number" label="EJE"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n5">
                                <v-text-field outlined dense v-model="dnp_od" type="number" label="DNP"
                                    @click="foco_dnp = true"></v-text-field>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="6">{{ suma_DNP }}
                    <v-card>
                        <v-card-title class="subheading font-weight-bold mt-n13 mb-n4">
                            <v-icon class="ml-1 mr-1" color="red" dark>mdi-eye-outline</v-icon>OI
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item class="mb-n4">
                                <v-text-field :prepend-inner-icon="sim_esfe_oi" @click:prepend-inner="simbo_esfe_oi()"
                                    outlined dense v-model="esfera_oi" type="number" label="ESFERA"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n4">
                                <v-text-field prepend-inner-icon="mdi-minus" outlined dense v-model="cilindro_oi"
                                    type="number" label="CILINDRO"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n4">
                                <v-text-field outlined dense v-model="eje_oi" type="number" label="EJE"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n5">
                                <v-text-field outlined dense v-model="dnp_oi" type="number" label="DNP"
                                    @click="foco_dnp = true"></v-text-field>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

            </v-row>
            <v-row class="mt-n5 mb-1">
                <v-col cols="12">
                    <v-card>
                        <v-list dense>
                            <v-list-item class="mb-n4">
                                <v-text-field prepend-inner-icon="mdi-plus" outlined dense v-model="add" type="number"
                                    label="ADD"></v-text-field>
                            </v-list-item>
                            <v-list-item class="mb-n6">
                                <v-text-field @click="foco_dnp = false" outlined dense v-model="dip" type="number"
                                    label="DIP"></v-text-field>
                            </v-list-item>

                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="12" class="mt-n3 mb-n3">
                    <v-textarea outlined dense v-model="comentarios" auto-grow filled label="Comentarios"
                        rows="1"></v-textarea>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dial_historial" max-width="590" transition="dialog-bottom-transition">
            <v-simple-table class="elevation-1 mt-2" fixed-header height="65vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha Receta
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in lista_recetas" :key="item.id" @click="selecciona(item)">
                            <td>RECETA EXTERNA DE LA FECHA {{ conviertefecha(item.fecha) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-dialog>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    nuevaReceta_externa,
    allRecente_externa,
    nuevo_medicion,
    nuevoCliente
} from '../../db'
export default {
    props: {
        data: ''
    },
    data() {
        return {
            dial_historial: false,
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            dial: false,
            esfera_od: '',
            cilindro_od: '',
            eje_od: '',
            dnp_od: '',
            sim_esfe_od: 'mdi-plus',
            sim_esfe_oi: "mdi-plus",
            val_esfe_od: 1,
            val_esfe_oi: 1,
            esfera_oi: '',
            cilindro_oi: '',
            eje_oi: '',
            dnp_oi: '',
            add: '',
            dip: '',
            nom_paciente: '',
            dni_cliente: '',
            comentarios: '',
            lista_recetas: [],
            foco_dnp: false,
            serie: ''
        }
    },
    created() {

        this.inicio()
    },
    mounted() {

    },
    beforeDestroy() {

    },
    computed: {
        suma_DNP() {
            if (this.foco_dnp) {
                this.dip = parseFloat(this.dnp_od) + parseFloat(this.dnp_oi)
            } else {
                this.dnp_od = this.dip / 2
                this.dnp_oi = this.dip / 2
            }
            return ''
        }
    },
    methods: {
        inicio() {
            this.nom_paciente = this.data.nom_cliente
            this.dni_cliente = this.data.dni_cliente
            this.dial = true
        },
        selecciona(data) {
            this.id = data.id
            this.esfera_od = data.esfera_od
            this.cilindro_od = data.cilindro_od
            this.eje_od = data.eje_od
            this.dnp_od = data.dnp_od
            this.esfera_oi = data.esfera_oi
            this.cilindro_oi = data.cilindro_oi
            this.eje_oi = data.eje_oi
            this.dnp_oi = data.dnp_oi
            this.add = data.add
            this.dip = data.dip
            this.comentarios = data.comentarios
            this.date = this.conviertefecha(data.fecha)
            this.dial_historial = false
        },
        async abre_historial() {
            var array = []
            var a = await allRecente_externa(this.dni_cliente).once('value')
            if (a.exists()) {
                a.forEach((item) => {
                    let data = item.val();
                    array.push(data);
                });
                this.lista_recetas = array
                this.dial_historial = true
            } else {
                store.commit('dialogosnackbar', 'Sin Informacion')
            }

        },
        conviertefecha(date) {
            return moment.unix(date).format('YYYY-MM-DD')
        },
        cierra() {
            this.$emit('cierra', false)
        },
        simbo_esfe_oi() {
            if (this.sim_esfe_oi == 'mdi-plus') {
                this.sim_esfe_oi = "mdi-minus"
                this.val_esfe_oi = -1
            } else {
                this.sim_esfe_oi = "mdi-plus"
                this.val_esfe_oi = 1
            }
        },
        simbo_esfe_od() {
            if (this.sim_esfe_od == 'mdi-plus') {
                this.sim_esfe_od = "mdi-minus"
                this.val_esfe_od = -1
            } else {
                this.sim_esfe_od = "mdi-plus"
                this.val_esfe_od = 1
            }
        },
        async Guarda_medicion() {
            if (this.dip == '') {
                alert('FALTAN AGREGAR DIP')
                return
            }
            store.commit("dialogoprogress", 1)
            if (this.add == '') {
                this.add = 0
            }
            var fec = moment(String(this.date)) / 1000
            var array = {
                id: moment().unix(),
                esfera_od: this.devuelve_simbolo(this.esfera_od * this.val_esfe_od),
                cilindro_od: '-'+this.cilindro_od,
                eje_od: this.eje_od,
                dnp_od: this.dnp_od,
                esfera_oi: this.devuelve_simbolo(this.esfera_oi * this.val_esfe_oi),
                cilindro_oi: '-'+this.cilindro_oi,
                eje_oi: this.eje_oi,
                dnp_oi: this.dnp_oi,
                add: this.devuelve_simbolo(this.add),
                dip: this.dip,
                comentarios: this.comentarios,
                fecha: fec,
                nom_cliente: this.nom_paciente,
                dni_cliente: this.dni_cliente,
                serie: this.obten_serie(),
                modo: 'EXTERNA',
            }
            store.commit('array_paciente', array)
            await this.agrega_rece_externa(array)
            await this.guarda_cliente()
            await nuevaReceta_externa(array.dni_cliente, array.id, array)
            store.commit("dialogoprogress", 1)
            this.cierra()
        },
        async guarda_cliente() {
            var fecha = moment(String(this.date)) / 1000
            var array = {
                activo: true,
                id: this.dni_cliente,
                tipodoc: 'DNI',
                documento: this.dni_cliente,
                nombre: this.nom_paciente.toUpperCase(),
                correo: '',
                departamento: 'LA LIBERTAD',
                provincia: 'TRUJILLO',
                distrito: 'TRUJILLO',
                ubigeo: 130101,
                direccion: '',
                telefono: '',
                activa_fecha: true,
                nacimiento: fecha,
                edad: 0,
                tienda: store.state.permisos.tienda,
                es_menor: false
            }
            await nuevoCliente(array.id, array)
        },
        async agrega_rece_externa(value) {
            var array = {
                id: value.id,
                nom_cliente: value.nom_cliente,
                dni_cliente: value.dni_cliente,
                tienda: store.state.permisos.tienda,
                especialista: 'RECETA EXTERNA',
                fecha: value.id,
                diagnostico: 'RECETA EXTERNA',
                comentarios: 'RECETA EXTERNA',
                recomendaciones: '',
                esfera_od: value.esfera_od,
                esfera_oi: value.esfera_oi,
                cilindro_od: value.cilindro_od,
                cilindro_oi: value.cilindro_oi,
                eje_od: value.eje_od,
                eje_oi: value.eje_oi,
                dnp_od: value.dnp_od,
                dnp_oi: value.dnp_oi,
                av_od: 0,
                av_oi: 0,
                pio_od: 0,
                pio_oi: 0,
                add: value.add,
                add_int: 0,
                dip: value.dip,
                estado: 'PENDIENTE',
                editado: false,
                array_recom: '',
                primer_especialista: '',
                modo: 'RECETA',
                key_cita: '',
                array_compra: '',
                serie: value.serie
            }
            console.log(array)
            await nuevo_medicion(array.id, array)
        },
        devuelve_simbolo(data) {
            var simbol = ""
            if (data > 0) {
                simbol = "+"
            }
            return simbol + parseFloat(data).toFixed(2)
        },
        regresa_serie(data) {
            if (data == 1) {
                return '1ERA'
            }
            if (data == 2) {
                return '2DA'
            }
            if (data == 3) {
                return '3ERA'
            }
            if (data == 4) {
                return 'FABRICACION'
            }
        },
        obten_serie() {
            var serie_od = 1
            var serie_oi = 1

            if (parseFloat(this.esfera_od) <= 6 && parseFloat(this.esfera_od) >= -6) {
                if (parseFloat(this.cilindro_od) <= 0 && parseFloat(this.cilindro_od) >= -2) {
                    serie_od = 1
                }
                if (parseFloat(this.cilindro_od * -1) <= -2.25 && parseFloat(this.cilindro_od * -1) >= -4) {
                    serie_od = 2
                }
                if (parseFloat(this.cilindro_od * -1) <= -4.25 && parseFloat(this.cilindro_od * -1) >= -6) {
                    serie_od = 3
                }
                if (parseFloat(this.cilindro_od * -1) <= -6.25) {
                    serie_od = 4
                }
            } else {
                if (this.esfera_od == '') {
                    serie_od = 1
                } else {
                    serie_od = 4
                }

            }

            if (parseFloat(this.esfera_oi) <= 6 && parseFloat(this.esfera_oi) >= -6) {
                if (parseFloat(this.cilindro_oi * -1) <= 0 && parseFloat(this.cilindro_oi * -1) >= -2) {
                    serie_oi = 1
                }
                if (parseFloat(this.cilindro_oi * -1) <= -2.25 && parseFloat(this.cilindro_oi * -1) >= -4) {
                    serie_oi = 2
                }
                if (parseFloat(this.cilindro_oi * -1) <= -4.25 && parseFloat(this.cilindro_oi * -1) >= -6) {
                    serie_oi = 3
                }
                if (parseFloat(this.cilindro_oi * -1) <= -6.25) {
                    serie_oi = 4
                }
            } else {
                if (this.esfera_oi == '') {
                    serie_od = 1
                } else {
                    serie_od = 4
                }
            }
            if (serie_od <= serie_oi) {
                return this.regresa_serie(serie_oi)
            } else {
                return this.regresa_serie(serie_od)
            }
        }
    },

}
</script>
