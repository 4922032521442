<template>
    <div class="pa-3">
        <v-card class="pa-1">
            <v-row class="mt-n2 pa-1">

            </v-row>
            <v-row class="mt-n2 pa-1">

                <v-col cols="4">
                    <v-select :items="array_filt" label="Filtro x " dense outlined v-model="tipo_filt"></v-select>
                </v-col>
                <v-col cols="2" v-if="tipo_filt == 'CLIENTE'">

                </v-col>
                <v-col cols="4" v-if="tipo_filt != 'CLIENTE'">

                </v-col>
                <v-col cols="2" v-if="tipo_filt == 'ESTADO'">
                    <v-select :items="array_estados" label="Estado" dense outlined v-model="estados"></v-select>
                </v-col>
                <v-col cols="2" v-if="tipo_filt == 'FECHA'">
                    <v-text-field type="date" outlined dense v-model="date" label="Fecha"></v-text-field>
                </v-col>
                <v-col cols="3" v-if="tipo_filt == 'CLIENTE'">
                    <v-autocomplete outlined dense autofocus label="Busca Cliente" auto-select-first v-model="busca_p"
                        :items="arra_clientes" append-icon="mdi-magnify" @click:append="filtra()"
                        @keyup.enter="filtra()"></v-autocomplete>
                </v-col>
                <v-col cols="1">
                    <v-btn small color="blue" class="white--text" fab @click="filtra()">
                        <v-icon dark>
                            mdi-filter
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="1">
                    <v-btn small color="error" class="white--text" fab @click="imprime_reporte()">
                        <v-icon dark>
                            mdi-printer
                        </v-icon>
                    </v-btn>
                </v-col>

            </v-row>
            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td>
                                <v-alert class="mt-2 mb-2" border="left" colored-border :color="item.color"
                                    elevation="2">
                                    <v-row>
                                        <v-col cols="9">
                                            <div class="text-h10" style="font-size:90%;">
                                                <strong> {{ item.doc_venta }} - {{
                                                    item.cliente_nombre }}</strong>
                                                <strong v-if="obten_telefono(item) != ''">
                                                    - Telf: {{ obten_telefono(item) }} <v-icon
                                                        @click="abre_mensaje(item, obten_telefono(item))" color="green">
                                                        mdi-whatsapp
                                                    </v-icon>
                                                </strong>

                                            </div>
                                            <div class="text-h10" style="font-size:90%;">
                                                <span v-if="item.comentario != ''">
                                                    - Obs: {{ item.comentario }}</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" class="pa-1 mb-4">
                                            <v-system-bar window color="white" class="pa-1">
                                                <v-spacer></v-spacer>
                                                <div v-if="false" class="text-h12"
                                                    style="font-size:80%; font-style: italic;">
                                                    <v-btn class="mr-6" color="success"
                                                        @click.prevent="obten_cod_pedido(item)" x-small>ver
                                                        Cod</v-btn>
                                                </div>
                                                <v-btn v-if="$store.state.permisos.master && item.estado == 'PENDIENTE'"
                                                    @click="eliminar_pe(item)" color="error" x-small>ELIMINAR</v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    v-if="item.total - item.total_a_cuenta == 0 && item.estado == 'PENDIENTE'"
                                                    @click="confirma_entrega(item)" color="warning"
                                                    x-small>ENTREGAR</v-btn>
                                                <v-icon v-if="false" color="info" large
                                                    :disabled="item.estado == 'COMPLETO'"
                                                    @click="confirma_entrega(item)">mdi-check-circle-outline</v-icon>
                                                <v-icon class="ml-6" large color="green"
                                                    @click="imprime(item)">mdi-printer</v-icon>
                                            </v-system-bar>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mt-n4 my-1 info" style="opacity: 0.22"></v-divider>
                                    <v-row class="mt-1 mb-n2" no-gutters>
                                        <v-col cols="3">

                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong class="red--text">F.VENT :</strong> {{
                                                    conviertefecha(item.fecha) }}
                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>VEND :</strong> {{ item.vendedor }}
                                            </div>

                                        </v-col>
                                        <v-col cols="6">
                                            <div class="text-h12 mr-6" style="font-size:75%; font-style: italic;"
                                                v-for="items in item.producto" :key="items.id" v-if="items.tipo == 'P'">
                                                <strong>-</strong> {{ items.nombre }}
                                                <strong class="red--text"
                                                    v-if="calcula_dias(items.dias_entrega, item) > 0">
                                                    - Listo en {{ calcula_dias(items.dias_entrega, item) }}
                                                    dias - <span v-if="Boolean(items.cod_pedido)"
                                                        style="font-size:95%">Cod:{{ items.cod_pedido
                                                        }}</span>
                                                </strong>
                                                <strong class="red--text id_card_rect"
                                                    v-if="calcula_dias(items.dias_entrega, item) <= 0">
                                                    - PENDIENTE DE ENTREGA
                                                </strong>
                                                <v-icon color=" green" @click="abre_progreso(item, items)"> mdi-eye
                                                </v-icon>
                                                <v-icon :disabled="fecha_venta(item)" v-if="item.estado != 'COMPLETO'"
                                                    class="ml-2" color="info" @click="imprime_envio(item, items)">
                                                    mdi-printer
                                                </v-icon>
                                            </div>
                                            <div class="text-h12 mr-6" style="font-size:75%; font-style: italic;"
                                                v-for="items in item.producto" :key="items.id" v-if="items.tipo != 'P'">
                                                <strong>-</strong> {{ items.nombre }}
                                            </div>
                                        </v-col>
                                        <v-col cols="2">
                                            <div class="text-h12 red--text" style="font-size:80%; font-style: italic;">
                                                <strong>A CUENTA :</strong> S/.{{ item.total_a_cuenta }}
                                                <v-icon color="green"
                                                    @click="abre_a_cuentas(item)">mdi-lead-pencil</v-icon>
                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>TOTAL :</strong> S/.{{ item.total }}
                                            </div>
                                        </v-col>
                                        <v-col cols="1">
                                            <div class="text-h12 red--text" style="font-size:80%; font-style: italic;">
                                                <strong>SALDO :</strong> S/.{{ item.total - item.total_a_cuenta }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-alert>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dial_progreso" max-width="700px">
            <v-card class="pa-12">
                <v-img v-if="false" src="/pedidos.gif" max-width="200" max-height="300" class="mx-auto"></v-img>
                <v-card-text class="mt-12 pa-6">
                    <v-slider readonly class="mt-12 " thumb-color="white" v-model="progress_producto"
                        :tick-labels="ticksLabels" :max="2" step="1" ticks="always" thumb-label="always" tick-size="1"
                        thumb-size="80">
                        <template v-slot:thumb-label="{ value }">
                            <v-img v-if="value == 0" src="/pedidos.gif" max-width="150" max-height="120"
                                class="mx-auto"></v-img>
                            <v-img v-if="value == 1" src="/bicelado.gif" max-width="150" max-height="150"
                                class="mx-auto mb-6"></v-img>
                            <v-img v-if="value == 2" src="/chek.gif" max-width="70" max-height="70"
                                class="mx-auto"></v-img>
                        </template>
                    </v-slider>
                    <v-row class="mt-4 mb-n6" dense v-if="item_selecto.ubicacion == 'TALLER'">
                        <v-col cols="4">
                        </v-col>
                        <v-col cols="4">
                        </v-col>
                        <v-col cols="4">
                            <v-card @click.prevent="confirma_llegada()">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/listo.gif"></v-img>
                                    <h6 block class="text-center">CONFIRMA ENTREGA</h6>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card class="pa-3 mt-1" v-for="item in arra_cods" :key="item.id">
                    <p style="font-size:85%;" class="text-center"><strong> Pedido N°: {{ item.id }}</strong>- <span
                            class="red--text">{{
                                item.producto.nombre }}</span>
                    </p>
                </v-card>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_a_cuentas" max-width="550">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dial_a_cuentas = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h3>RESTA S/.{{ item_selecto.total - item_selecto.total_a_cuenta }}</h3>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="agrega_montos()">mdi-plus</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table fixed-header height="40vh" dense>
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Observacion
                                </th>
                                <th class="text-left">
                                    Monto
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in item_selecto.detalle_a_cuenta" :key="item.id">
                                <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                                <td style="font-size:85%;">{{ item.observacion }}</td>
                                <td style="font-size:85%;">S/.{{ item.monto }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            <v-dialog v-model="agrega_monto" max-width="500">
                <div>
                    <v-system-bar window dark>
                        <v-icon color="red" large @click="agrega_monto = false">mdi-close</v-icon>
                        <v-spacer></v-spacer>
                        <h3>RESTA S/.{{ item_selecto.total - item_selecto.total_a_cuenta }}</h3>
                        <v-spacer></v-spacer>
                        <v-icon color="green" large @click="guarda_acuenta()">mdi-content-save</v-icon>
                    </v-system-bar>
                </div>
                <v-card class="pa-3">
                    <h4 class="text-center">Monto total = S/.{{ sumat_total() }}</h4>
                    <v-row class="mt-1" dense v-for="item in modopagos" :key="item.modo">
                        <v-col cols="12">
                            <v-row dense no-gutters>
                                <v-col cols="2">
                                    <v-img class="ml-2" height="35" width="35" :src="busca_ico(item.nombre)"></v-img>
                                </v-col>
                                <v-col cols="10">
                                    <v-text-field class="ml-n8" :autofocus="item.nombre == 'EFECTIVO'" prefix="S/."
                                        outlined dense v-model="item.monto" type="number"
                                        :label="item.nombre"></v-text-field>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-dialog>
        <v-dialog v-model="ver_cod" max-width="600">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="ver_cod = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>

                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-card class="pa-3 mt-1" v-for="item in arra_cods" :key="item.id">
                    <p style="font-size:85%;" class="text-center"><strong> Pedido N°: {{ item.id }}</strong>- <span
                            class="red--text">{{
                                item.producto.nombre }}</span>
                    </p>
                </v-card>
            </v-card>
        </v-dialog>
        <imprime v-if="genera_pdf" :data="seleccionado" @cierra="genera_pdf = $event" />
        <sms_unico v-if="dial_sms_u" @cierra="dial_sms_u = $event" :data="num_selecto"></sms_unico>
    </div>
</template>

<script>
import {
    allpendientes,
    edita_pendiente,
    consulta_Cabecera,
    grabaDatoC,
    nuevoflujo,
    allpedidos,
    edita_pedido,
    consultaDetalle,
    editaMontura,
    busca_receta_externa,
    busca_medicion,
    busca_parametros
} from '../../db'
import {
    send_sms
} from '../../envio_sms'
import {
    pdf_envio_pedido
} from '../../pdf'
import {
    pdf_pendiente
} from '../../pdf_pendientes'
import store from '@/store/index'
import moment from 'moment'
import imprime from '@/components/dialogos/dialog_imprime'
import sms_unico from '@/views/mensajeria/sms_unico'
export default {
    components: {
        imprime,
        sms_unico
    },
    data: () => ({
        dial_sms_u: false,
        genera_pdf: false,
        seleccionado: [],
        dial_progreso: false,
        dial_a_cuentas: false,
        agrega_monto: false,
        desserts: [],
        arra_clientes: [],
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        busca_p: '',
        progress_producto: '',
        array_filt: ['ESTADO', 'FECHA', 'CLIENTE'],
        tipo_filt: 'ESTADO',
        ticksLabels: [
            'PEDIDO',
            'TALLER',
            'TIENDA',
        ],
        monto_a_cuenta: '',
        observacion_a_cuenta: '',
        item_selecto: [],
        cabecera_selecta: [],
        modopagos: [{
            nombre: 'EFECTIVO',
            monto: 0
        }],
        array_estados: ['PENDIENTE', 'COMPLETO', 'ANULADO'],
        estados: 'PENDIENTE',
        ver_cod: false,
        arra_cods: [],
        num_cliente: '',
        num_selecto: ''
    }),

    created() {
        this.carga_clientes()
        this.filtra()
    },
    computed: {
        listafiltrada() {

            var array = this.desserts
            return array
        },
    },

    methods: {
        async abre_mensaje(data, telf) {
            console.log(data)
            data.telefono = telf
            this.num_selecto = data
            this.dial_sms_u = true
        },
        async imprime_reporte() {
            var data = this.desserts
            for (var i = 0; i < data.length; i++) {
                // console.log(data[i])
            }
            pdf_pendiente(data, '80')
        },
        async filtra() {
            if (this.tipo_filt == 'ESTADO') {
                if (this.estados == 'COMPLETO') {
                    var snap = await allpendientes().limitToLast(10).orderByChild('estado').equalTo(this.estados).once("value");
                } else {
                    var snap = await allpendientes().orderByChild('estado').equalTo(this.estados).once("value");
                }

            }
            if (this.tipo_filt == 'FECHA') {
                var snap = await allpendientes().orderByChild('fecha')
                    .startAt(moment(String(this.date)) / 1000)
                    .endAt(moment(String(this.date)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).once("value");
            }
            if (this.tipo_filt == 'CLIENTE') {
                this.num_cliente = this.busca_p.split('/')[0].trim()
                var snap = await allpendientes().orderByChild('cliente_dni').equalTo(this.num_cliente).once("value");
            }
            this.desserts = []
            let array = [];
            snap.forEach((item) => {
                let data = item.val()
                if (data.estado == 'COMPLETO') {
                    data.color = 'green'
                } else {
                    data.color = 'orange'
                }

                array.push(data);
            });
            array.sort(function (a, b) {
                if (parseFloat(a.fecha) < parseFloat(b.fecha)) {
                    return 1;
                }
                if (parseFloat(a.fecha) > parseFloat(b.fecha)) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            this.desserts = array;

        },
        obten_telefono(value) {
            var a = (store.state.clientes.find(e => e.id == value.cliente_dni))
            if (a == undefined) {
                return value.telefono
            } else {
                return a.telefono
            }
        },
        agrega_montos() {
            this.agrega_monto = true
        },
        sumat_total() {
            var suma = 0
            for (var i = 0; i < this.modopagos.length; i++) {
                if (this.modopagos[i].monto != '') {
                    suma = suma + parseFloat(this.modopagos[i].monto)
                }
            }
            return suma
        },
        abre_a_cuentas(item) {
            console.log(item)
            this.monto_a_cuenta = ''
            this.observacion_a_cuenta = ''
            this.item_selecto = item
            this.carga_pagos(item)
            this.dial_a_cuentas = true
        },
        carga_pagos(item) {
            this.modopagos = []
            var modos = store.state.tipo_pago
            for (var i = 0; i < modos.length; i++) {
                var data = modos[i]
                var mont = ''
                if (data == 'EFECTIVO') {
                    mont = item.total - item.total_a_cuenta
                }
                this.modopagos.push({
                    nombre: data,
                    monto: mont
                })
            }
        },
        async guarda_acuenta() {
            var restante = this.item_selecto.total - this.item_selecto.total_a_cuenta

            if (parseFloat(this.sumat_total()) != 0 && parseFloat(this.sumat_total()) <= restante) {
                store.commit("dialogoprogress", 1)
                var array = this.item_selecto.detalle_a_cuenta
                array.push({
                    id: array.length,
                    monto: this.sumat_total(),
                    observacion: this.observacion_a_cuenta,
                    fecha: moment().unix(),
                    estado: 'ACEPTADO'
                })
                var suma = parseFloat(this.item_selecto.total_a_cuenta) + parseFloat(this.sumat_total())
                await this.flujo_caja()
                await edita_pendiente(this.item_selecto.id, 'total_a_cuenta', suma)
                await grabaDatoC(this.item_selecto.doc_venta, 'a_cuenta', suma)
                await edita_pendiente(this.item_selecto.id, 'total_a_cuenta', suma)
                await edita_pendiente(this.item_selecto.id, 'detalle_a_cuenta', array)
                this.dial_a_cuentas = false
                store.commit("dialogoprogress", 1)
                this.filtra()
            } else {
                alert('INGRESE MONTOS VALIDOS')
            }
        },
        async flujo_caja() {
            var modos = this.modopagos
            for (var i = 0; i < modos.length; i++) {
                var data = modos[i]
                if (data.monto != '') {
                    var flujo = {
                        operacion: 'ingreso',
                        observacion: 'A Cuenta - ' + this.item_selecto.doc_venta,
                        numeracion_doc: this.item_selecto.doc_venta,
                        modo: data.nombre,
                        fecha: moment().unix(),
                        total: data.monto,
                        estado: 'activo',
                        responsable: store.state.permisos.correo.slice(0, -13),
                        sujeto: store.state.permisos.correo.slice(0, -13),
                    }
                    await nuevoflujo(flujo)
                }
            }
        },
        carga_clientes() {
            var array = store.state.clientes
            for (var i = 0; i < array.length; i++) {
                this.arra_clientes[this.arra_clientes.length] = array[i].documento + ' / ' + array[i].nombre
            }
        },
        async onDataChange(items) {

        },
        async obten_cod_pedido(val) {
            this.arra_cods = []
            var a = await allpedidos().orderByChild('doc_venta').equalTo(val.doc_venta).once('value')
            a.forEach((item) => {
                this.arra_cods.push(item.val())
            })
            this.ver_cod = true
        },
        async confirma_entrega(value) {
            var saldo = value.total - value.total_a_cuenta
            if (saldo != 0) {
                alert('NO SE PUEDE ENTREGAR SI TIENE SALDO PENDIENTE DE PAGO')
            } else {
                var array = value.producto
                var suma = 0
                for (var i = 0; i < array.length; i++) {
                    if (array[i].ubicacion == 'TIENDA') {
                        suma++
                    }
                }
                //if (suma == array.length) {
                if (confirm('SEGURO QUE ESTE PEDIDO FUE ATENDIDO?')) {
                    edita_pendiente(value.id, 'estado', 'COMPLETO')
                } else {
                    alert('AUN EXISTEN PRODUCTOS PENDIENTES DE LLEGADA')
                }
                //  }
                this.filtra()
            }
        },
        fecha_venta(data) {
            if (store.state.permisos.master) {
                return false
            } else {
                var hoy = moment().unix()
                var receta = moment.unix(data.fecha).add(1440, 'm').unix()
                if (receta > hoy) {
                    return false
                } else {
                    return true
                }
            }

        },
        async imprime_envio(cabecera, detalle) {
            var cods = []
            var a = await allpedidos().orderByChild('doc_venta').equalTo(cabecera.doc_venta).once('value')
            a.forEach((item) => {
                var data = item.val()
                if (data.tienda == store.state.permisos.tienda) {
                    cods.push(data)
                }
            })
            if (cabecera.modo_receta == 'INTERNA') {
                var receta = await busca_medicion(cabecera.id_receta).once("value")
            } else {
                var receta = await busca_receta_externa(cabecera.cliente_dni, cabecera.id_receta).once("value")
            }
            if (cods[0].producto.id_parametro != undefined) {

                var snap = await busca_parametros(cabecera.cliente_dni, cods[0].producto.id_parametro).once("value")
                if (snap.exists()) {
                    cabecera.parametro = snap.val()
                }
            }

            cabecera.entrega = this.calcula_dias(detalle.dias_entrega, cabecera)
            cabecera.cod_pedido = cods
            pdf_envio_pedido(cabecera, receta.val(), detalle)
        },
        async eliminar_pe(val) {
            if (confirm('SEGURO DE ELIMINAR ESTE PEDIDO?')) {
                store.commit("dialogoprogress", 1)
                var items = await consultaDetalle(val.doc_venta).once("value")
                for (var i = 0; i < items.val().length; i++) {
                    var data = items.val()[i]
                    if (data.id[0] == 'M') {
                        await editaMontura(data.id + '/stock', 1)
                    }
                }
                await edita_pendiente(val.id, 'estado', 'ANULADO')
                this.filtra()
                store.commit("dialogoprogress", 1)
            }
        },
        async confirma_llegada() {
            store.commit("dialogoprogress", 1)
            if (this.cabecera_selecta.telefono != '') {
                this.enviar_sms(this.cabecera_selecta.telefono)
            }
            var snap = await allpedidos().orderByChild('id_a_cuenta').equalTo(this.cabecera_selecta.id).once('value')
            var pedido = []
            if (snap.exists()) {
                snap.forEach((item) => {
                    pedido.push(item.val())
                })
                var number = pedido.find(item => item.producto.nombre == this.item_selecto.nombre)
                edita_pedido(number.id, 'pedido_en_tienda', true)
            }
            var array_producto = this.cabecera_selecta.producto
            var ubica = array_producto.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            await edita_pendiente(this.cabecera_selecta.id, 'producto/' + ubica + '/ubicacion', 'TIENDA')
            store.commit("dialogoprogress", 1)
            this.filtra()
            this.dial_progreso = false
        },
        async enviar_sms(num) {

            var numero = ''
            if (num.length == 9) {
                numero = '51' + num
            }
            if (num.length == 11) {
                numero = num
            }
            var mensaje = 'Hola tus lentes oftalmicas ya se encuentran disponible en tienda ' +
                'puedes acercarte a: \n' + '📍' + store.state.baseDatos.direccion + '\n \n' +
                '* Siguenos por Facebook e Intagram como📲 Opticenter Vision \n' +
                'Donde por ser clientes encontraras promociones y sorteos exclusivos.🤗'

            if (numero != '') {
                send_sms(mensaje, numero)
            }

        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM hh:mm A')
        },
        async abre_progreso(cabecera, item) {
            var ubicacion = this.ticksLabels.indexOf(item.ubicacion)
            this.progress_producto = ubicacion
            this.item_selecto = item
            this.cabecera_selecta = cabecera
            this.arra_cods = []
            var a = await allpedidos().orderByChild('doc_venta').equalTo(cabecera.doc_venta).once('value')
            a.forEach((item) => {
                var data = item.val()
                if (data.tienda == store.state.permisos.tienda) {
                    this.arra_cods.push(data)
                }

            })
            this.dial_progreso = true
        },
        async imprime(item) {
            var snap = await consulta_Cabecera(item.doc_venta).once("value")
            this.seleccionado = snap.val()
            this.genera_pdf = true
        },
        calcula_dias(dias, item) {
            var hoy = moment()
            var fin = moment.unix(item.fecha).add(dias, 'd')
            return fin.diff(hoy, 'days') + 1
        },
        busca_ico(data) {
            if (data == 'YAPE') {
                return 'yape.png'
            }
            if (data == 'TARJETA') {
                return 'tarjeta.png'
            }
            if (data == 'EFECTIVO') {
                return 'cash.png'
            }
            if (data == 'PLIN') {
                return 'plin.webp'
            }
            if (data == 'TRANSFERENCIA') {
                return 'bank.png'
            }
        },
    }
}
</script>

<style scoped>
@keyframes anim {
    0% {
        background-color: rgb(255, 255, 255);

    }

    50% {
        background-color: rgb(255, 196, 196);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

.id_card_rect {
    animation-name: anim;
    animation-duration: 2s;
    animation-iteration-count: infinite;

}
</style>
