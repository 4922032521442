<template>
    <v-dialog v-model="dial" max-width="60vh" transition="dialog-bottom-transition">
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="dial = false">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4 class='red--text'>{{ time }}</h4>
            </v-system-bar>
        </div>

        <v-card class="pa-2" v-if='!dial_marcar'>
            <v-row dense>
                <v-col cols="12">
                    <v-card @click.prevent="dial_marcar = true">
                        <v-container>
                            <v-img class="mx-auto" height="70" width="70" src="/marcador.png"></v-img>
                            <h4 block class="text-center pa-1">Marcar Asistencia</h4>

                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card @click.prevent="abrir_administrar()" v-if="$store.state.permisos.master">
                        <v-container>
                            <v-img class="mx-auto" height="70" width="70" src="/repor.png"></v-img>
                            <h4 block class="text-center pa-1">Administrar</h4>
                        </v-container>
                    </v-card>
                </v-col>

            </v-row>
        </v-card>

        <v-dialog v-model="dial_final" max-width="50vh" transition="dialog-bottom-transition" persistent>
            <v-card class="pa-2">
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="marca('ingreso')" :disabled="modo == 'ingreso'"
                            :class="modo == 'ingreso' ? 'green' : ''">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/relojj.png"></v-img>
                                <h5 block class="text-center pa-1">INGRESO</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="marca('salida')" :disabled="modo == 'salida'"
                            :class="modo == 'salida' ? 'red' : ''">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/relojj.png"></v-img>
                                <h5 block class="text-center pa-1">SALIDA</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card class="mt-4">
                    <h4 class="pa-2">Documento : {{ empleado.documento }}</h4>
                    <h4 class="pa-2">Usuario : {{ empleado.nombre }}</h4>
                </v-card>

                <v-row dense class="mt-12">
                    <v-col cols="6">
                        <v-btn @click="cierra()" block color="error" small>Cancelar</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn @click="guardar()" :disabled="modo == ''" block color="info" small>Guardar</v-btn>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_rostro" max-width="50vh" transition="dialog-bottom-transition">
            <FaceDetection v-if="dial_rostro" class="mt-n1" @nombre="nombre_rostro($event)" :faceMatcher="faceMatcher">
            </FaceDetection>
        </v-dialog>
        <v-dialog v-model="dial_qr" max-width="50vh" transition="dialog-bottom-transition">
            <StreamBarcodeReader :video-constraints="videoConstraints" v-if="cameraReady" ref="video"
                @decode="(a, b, c) => onDecode(a, b, c)" @loaded="() => onLoaded()">
            </StreamBarcodeReader>
        </v-dialog>
        <v-dialog v-model="dial_marcar" max-width="50vh" transition="dialog-bottom-transition">

            <v-card class="pa-2">
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="dial_rostro = true, dial_marcar = false">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/rostro.png"></v-img>
                                <h4 block class="text-center pa-1">Rostro</h4>

                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent=" setFrontCamera(), dial_qr = true, dial_marcar = false">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/qr_scan.png"></v-img>
                                <h4 block class="text-center pa-1">QR</h4>
                            </v-container>
                        </v-card>
                    </v-col>

                </v-row>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import axios from "axios"
import store from '@/store/index'
import moment from 'moment'
import FaceDetection from "./FaceDetection.vue";
import * as faceapi from 'face-api.js';
import {
    marca_asis,
    marca_asis_personal
} from '../../db'
import {
    StreamBarcodeReader
} from "vue-barcode-reader";
export default {
    name: 'caja',
    components: {
        StreamBarcodeReader,
        FaceDetection
    },
    data() {
        return {
            videoConstraints: {},
            cameraReady: false,
            dial_final: false,
            dial_marcar: false,
            dial_rostro: false,
            dial_qr: false,
            leer: true,
            dial: false,
            marcar: false,
            id: null,
            text: '',
            empleado: '',
            modo: '',
            interval: null,
            time: null,
            faceMatcher: null
        }
    },
    async created() {
        console.log()
        store.commit("dialogoprogress", 1);
        // await this.loadModels(); // Cargar modelos en segundo plano
        await this.loadLabeledDescriptors();
        this.dial = true
        store.commit("dialogoprogress", 1);
        // update the time every second
        this.interval = setInterval(() => {
            // Concise way to format time according to system locale.
            // In my case this returns "3:48:00 am"
            this.time = moment(String(new Date)).format('hh:mm:ss A / DD-MM')
        }, 1000)
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
    computed: {

    },


    /*  async mounted() {
          //pthis.startVideo(); // Iniciar la cámara primero
          this.loadModels(); // Cargar modelos en segundo plano
          //await this.loadLabeledDescriptors();
      },*/
    methods: {
        async setFrontCamera() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front')) || videoDevices[0];
                console.log(videoDevices)
                if (frontCamera) {

                    this.videoConstraints = {
                        video: {
                            deviceId: frontCamera.deviceId ? { exact: frontCamera.deviceId } : undefined
                        }
                    };
                    this.cameraReady = true;
                } else {
                    console.error('No se encontró una cámara frontal.');
                }
            } catch (error) {
                console.error('Error al obtener la cámara frontal:', error);
            }
        },

        /* async loadModels() {
             await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
             await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
             await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
             this.modelsLoaded = true; // Indicar que los modelos se han cargado
         },*/
        async loadLabeledDescriptors() {
            const labeledImages = store.state.empleados.map(emp => emp);
            if (labeledImages != '') {
                console.log("asd")
                this.labeledDescriptors = await Promise.all(
                    labeledImages.map(async (item) => {
                        const received = new Float32Array(item.face)
                        console.log(item.id)
                        return new faceapi.LabeledFaceDescriptors(item.id, [received]);
                    })
                );

                this.faceMatcher = new faceapi.FaceMatcher(this.labeledDescriptors);
            }
        },
        abrir_administrar() {
            this.$router.push({
                name: 'panel_asistencia'
            })

        },
        async guardar() {
            store.commit("dialogoprogress", 2)
            var array = {
                fecha: moment().unix(),
                modo: this.modo,
                nombre: this.empleado.nombre,
                documento: this.empleado.documento
            }
            await marca_asis(array)
            await marca_asis_personal(array.documento, array)
            store.commit("dialogoprogress", 2)
            this.dial_final = false
            /*if (confirm('seguro guardar la asistencia?')) {
               
            }*/
        },
        marca(data) {
            this.modo = data
        },
        nombre_rostro(data) {

            var result = store.state.empleados.find(a => a.id == data)
            console.log(result)
            this.empleado = result
            this.dial_final = true
            this.dial_rostro = false
            this.dicta_nombre(result)

        },
        onDecode(a, b, c) {
            console.log(a, b, c);
            this.text = a;
            var result = store.state.empleados.find(a => a.id == this.text)
            if (result == undefined) {
                alert('Codigo no existe')
                this.text = "";
            } else {
                this.empleado = result
                this.dicta_nombre(result)
                this.dial_final = true
                this.dial_qr = false
            }
            /*if (this.id) clearTimeout(this.id);
            this.id = setTimeout(() => {
                if (this.text === a) {
                    this.text = "";
                }
            }, 2000);*/

        },
        dicta_nombre(result) {
            let indice = result.nombre.indexOf(" ")
            let indice2 = result.nombre.indexOf(",")
            var nom = String(result.nombre.substring(indice2 + 2, result.nombre.length)).trim()
            let indc = nom.indexOf(" ")
            var vend = nom.substring(0, indc) + ' ' + result.nombre.substring(0, indice);
            var utterance = new SpeechSynthesisUtterance(vend);
            utterance.rate = 0.95;
            utterance.lang = 'es';
            window.speechSynthesis.speak(utterance);
        },
        onLoaded() {
            console.log("load");
        },
        cierra() {
            this.$emit('cierra', false)
        },
    },

}
</script>
