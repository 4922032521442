<template>
    <v-dialog v-model="dial" fullscreen hide-overlay transition="dialog-bottom-transition">
        <div>
            <v-system-bar window dark height="40">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>

                <v-spacer></v-spacer>
                <h3>Periodo : {{ periodo }}</h3>
            </v-system-bar>
        </div>

        <v-card class="">
            <v-simple-table fixed-header height="90vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th style="font-size:75%;" class="text-left"> </th>
                            <th v-for="item in dias_mes" :key="item" style="font-size:75%;"
                                :class="obten_dia(item) == 'dom' ? 'text-center red--text' : ''">
                                {{ obten_dia(item) }}
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style="font-size:75%;" class="text-left"> </th>
                            <th v-for="item in dias_mes" :key="item" style="font-size:75%;" class="text-center">
                                {{ item }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada " :key="item[0].nombre">
                            <td style="font-size:75%;" width="200">{{ item[0].nombre }}</td>
                            <th width="10" v-for="items in dias_mes " :key="items" style="font-size:75%;"
                                class="text-center" @click="editar(item, items)">
                                <p :class="item[items].estado == 'A' ? 'green--text' : item[items].estado == 'F' ? 'red--text'
                                    : item[items].estado == 'D' ? 'blue--text' : 'orange--text'">
                                    {{ item[items].estado }}
                                </p>
                            </th>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dial_nueva" max-width="450px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_nueva = !dial_nueva">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class=" text-center">{{ selecto.nombre }}</h4>
                <v-text-field readonly type="date" outlined dense v-model="date" label="Fecha"></v-text-field>
                <v-select :items="array_estado" label="Estado" outlined v-model="estado" dense></v-select>

                <v-row dense class="">
                    <v-col cols="6" class="text-center">
                        <v-text-field type="time" outlined dense v-model="h_ingreso"
                            label="Hora ingreso"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <v-text-field type="time" outlined dense v-model="h_salida" label="Hora Salida"></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field outlined dense v-model="observacion" label="Observacion"></v-text-field>
                <v-btn v-if="false" block class="" color="warning" small @click="guarda()">GUARDAR</v-btn>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import moment from 'moment'
import store from '@/store/index'
import {
    nuevo_medicion,
} from '../../db'
export default {

    props: {
        data: '',
        periodo: null
    },
    name: 'caja',
    data() {
        return {
            dial_nueva: false,
            selecto: [],
            array_estado: ['ASISTENCIA', 'FALTA', 'DESCANSO', 'PERMISO'],
            estado: 'ASISTENCIA',
            observacion: '',
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            h_ingreso: '',
            h_salida: '',
            dial: false,
            dias_mes: '',
            dias_sem: ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'],
        }
    },
    created() {
        this.dial = true
        this.dias_mes = moment(String(this.periodo), 'MM-YYYY').daysInMonth()
    },
    computed: {
        listafiltrada() {
            return this.data

        },

    },
    methods: {
        obten_dia(data) {
            var fecha = data + '-' + this.periodo
            return this.dias_sem[moment(String(fecha), 'DD-MM-YYYY').days()]
        },
        editar(a, b) {
            console.log(a, b)
            var pos = this.listafiltrada.map(e => e[0]).indexOf(a[0])

            this.date = moment(String(b + '-' + this.periodo), 'DD-MM-YYYY').format('YYYY-MM-DD')
            this.selecto = {
                nombre: a[0].nombre,
                pos_a: pos,
                pos_b: b,
                date: this.date
            }

            var data = this.listafiltrada[pos][b]
            if (data.estado == '') {
                alert('Sin vinculo laboral')
                return
            }
            if (data.h_ingreso != undefined) {
                this.h_ingreso = data.h_ingreso
            }
            if (data.h_salida != undefined) {
                this.h_salida = data.h_salida
            }
            if (data.observacion != undefined) {
                this.observacion = data.observacion
            }

            if (data.estado == 'A') {
                this.estado = 'ASISTENCIA'
            }
            if (data.estado == 'F') {
                this.estado = 'FALTA'
            }
            if (data.estado == 'P') {
                this.estado = 'PERMISO'
            }
            if (data.estado == 'D') {
                this.estado = 'DESCANSO'
            }

            this.dial_nueva = true
        },
        cierra() {
            this.$emit('cerrar', true);
        }
    },

}
</script>

<style>
.scroll {
    overflow-y: scroll
}
</style>
